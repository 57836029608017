import XLSX from 'xlsx';

const excelDownloader = (json_data, filename, sheet_name = 'data') => {
    const data = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, sheet_name);
    XLSX.writeFile(wb,`${filename}`);
};

const excelDownloaderWithAdditionalSheet = (json_data, first_sheet_name = 'data', additional_data, filename) => {
    const data = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, first_sheet_name);
    // additional data is objects, the key would be the sheet name and the value of key should be array of objects
    for (const key in additional_data) {
        if (additional_data[key].data && additional_data[key].data.length > 0) {
            const newDataSheet = XLSX.utils.json_to_sheet(additional_data[key].data);
            XLSX.utils.book_append_sheet(wb, newDataSheet, additional_data[key].sheet_name);
        }
    }
    XLSX.writeFile(wb,`${filename}`);
};

const excelReader = async (filename) => {
    let workbook = XLSX.read(await filename.arrayBuffer());
    let sheetNames = workbook.SheetNames;

    let sheetIndex = 1;

    let df = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[sheetIndex-1]]);
    return df[0].Job_Preference_ID || null;
};

const debounce = (fn, delay) => {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        const args = arguments;
        const that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
};

export {
    excelDownloader,
    excelReader,
    excelDownloaderWithAdditionalSheet,
    debounce
};
